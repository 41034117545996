import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { NavLink, useLocation } from "react-router-dom";
import Notification from "./Modals/NotificationBellModal/Notification";
import Profile from "./Modals/ProfileModal/Profile";
import EditProfile from "./Modals/EditProfileModal/EditProfile";
import useAuthStore from "../../store/authStore";

const Navbar = () => {
  const { user } = useAuthStore((state) => state);
  //STATES
  const [showModal, setShowModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);

  const [showMobileNav, setShowMobileNav] = useState(false);

  //functions
  const handleShowProfile = () => {
    setShowProfile(true);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const showToRoles = ["admin", "region"];
  const onlyAdmin = ["admin"]

  const location = useLocation()
  useEffect(() => {
    if(location.pathname) setShowMobileNav(false)
  }, [location.pathname])
  

  return (
    <div className="navbar">
      <div className="inner_div">
        <NavLink to={"/dashboard"}>
          <img
            className="horizontal_logo pointer"
            src={process.env.PUBLIC_URL + "/assets/icons/horizontalLogo.svg"}
            alt=""
          />
        </NavLink>
        {/* ------------------PROFILE-------------------- */}
        <div className="profile pointer" onClick={handleShowProfile}>
          <img
            className="profile_logo"
            src={
              user?.avatar
                ? user?.avatar
                : `${process.env.PUBLIC_URL}/assets/images/user.svg`
            }
            alt=""
          />
          <h5>Hi {user.name.split(" ")[0]}</h5>
          {showEditProfile ? (
            <EditProfile setShowEditProfile={setShowEditProfile} />
          ) : (
            <Profile
              showProfile={showProfile}
              setShowProfile={setShowProfile}
              setShowEditProfile={setShowEditProfile}
            />
          )}
        </div>

        {/* ---------------NOTIFICATION-BELL------------- */}
        <img
          onClick={handleShowModal}
          className="bell pointer"
          src={process.env.PUBLIC_URL + "/assets/icons/notification_bell.svg"}
          alt=""
        />
        <Notification showModal={showModal} setShowModal={setShowModal} />
        <div
          className="navbar-toggler"
          onClick={() => setShowMobileNav(true)}
          style={{ display: "none" }}
        >
          <span className="navbar-toggler-line"></span>
        </div>
      </div>

      {/* NAVIGATION BARS */}

      <div className={`logo_div ${showMobileNav ? "show" : ""}`}>
        <span
          className="closeNavbar"
          onClick={() => setShowMobileNav(false)}
        ></span>
        <ul className="navbar_icons">
          <li>
            <NavLink to="/dashboard">
              {({ isActive }) => (
                <div>
                  <img
                    className="navbar_logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/" +
                      (isActive ? "home_active.svg" : "home.svg")
                    }
                    alt="home"
                  />
                  <span className="navText">Dashboard</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            {showToRoles.includes(user.role) && (
              <NavLink to={"/role-management"}>
                {({ isActive }) => (
                  <div>
                    <img
                      className="navbar_logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/" +
                        (isActive ? "admin_active.svg" : "admin.svg")
                      }
                      alt="role-management"
                    />
                    <span className="navText">Role Management</span>
                  </div>
                )}
              </NavLink>
            )}
          </li>
          <li>
            <NavLink to={"/youth-section"}>
              {({ isActive }) => (
                <div>
                  <img
                    className="navbar_logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/" +
                      (isActive ? "users_active.svg" : "users.svg")
                    }
                    alt="youth-section"
                  />
                  <span className="navText">Youth section</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink to={"/announcement"}>
              {({ isActive }) => (
                <div>
                  <img
                    className="navbar_logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/" +
                      (isActive ? "news_active.svg" : "news.svg")
                    }
                    alt="announcement"
                  />
                  <span className="navText">Announcement</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink to={"/notification"}>
              {({ isActive }) => (
                <div>
                  <img
                    className="navbar_logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/" +
                      (isActive
                        ? "notification_active.svg"
                        : "notification.svg")
                    }
                    alt="notification"
                  />
                  <span className="navText">Notification</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink to={"/campus-connect"}>
              {({ isActive }) => (
                <div>
                  <img
                    className="navbar_logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/" +
                      (isActive ? "school_active.svg" : "school.svg")
                    }
                    alt="campus-connect"
                  />
                  <span className="navText">Campus connect</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink to={"/gallery"}>
              {({ isActive }) => (
                <div>
                  <img
                    className="navbar_logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/" +
                      (isActive ? "gallery_active.svg" : "gallery.svg")
                    }
                    alt="gallery"
                  />
                  <span className="navText">Gallery</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink to={"/listing"}>
              {({ isActive }) => (
                <div>
                  <img
                    className="navbar_logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/" +
                      (isActive ? "account_active.svg" : "account.svg")
                    }
                    alt="listing"
                  />
                  <span className="navText">Listing</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink to={"/contact-us"}>
              {({ isActive }) => (
                <div>
                  <img
                    className="navbar_logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/" +
                      (isActive ? "message_active.svg" : "message.svg")
                    }
                    alt="contact-us"
                  />
                  <span className="navText">Contact Us</span>
                </div>
              )}
            </NavLink>
          </li>
          <li>
            {onlyAdmin.includes(user.role) && (
              <NavLink to={"/events"}>
                {({ isActive }) => (
                  <div>
                    <img
                      className="navbar_logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/" +
                        (isActive ? "event_selected.svg" : "event.svg")
                      }
                      alt="hamburger"
                    />
                    <span className="navText">Sparsh 2024</span>
                  </div>
                )}
              </NavLink>
            )}
          </li>
          <li>
            {onlyAdmin.includes(user.role) && (
              <NavLink to={"/hamburger"}>
                {({ isActive }) => (
                  <div>
                    <img
                      className="navbar_logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/" +
                        (isActive ? "menu_selected.svg" : "menu.svg")
                      }
                      alt="hamburger"
                    />
                    <span className="navText">Hamburger Settings</span>
                  </div>
                )}
              </NavLink>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
