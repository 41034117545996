import React from "react";
import "./Bottom.scss";
import useAuthStore from "../../store/authStore";
import useAuthTokenExpiration from "../../hooks/useAuthTokenExpiration";
import { useNavigate } from "react-router-dom";

const Bottom = () => {
  const date = new Date()
  const navigate = useNavigate()
  const { logoutUser } = useAuthStore((state) => state);
  useAuthTokenExpiration(()=>logoutUser(navigate));

  return (
    <div className="bottom">
      <h6>
        Copyright 2020-{date.getFullYear()} Faithbook. All Rights Reserved by ICYM AGRA REGION.
      </h6>
    </div>
  );
};

export default Bottom;
