import React, { useState } from 'react'
import DownloadModal from './Modals/DownloadModal/DownloadModal';
import { DebounceInput } from 'react-debounce-input';

const Header = ({ setQuery }) => {

    const [showDownloadModal, setShowDownloadModal] = useState(false)

    const handleShowDownloadModal = () => setShowDownloadModal(true)

    const handleSearch = (e) => setQuery(e.target.value)

    return (
        <div className="head-wrapper">
            <div class="notifications-roles"><h3>Sparsh 2024</h3></div>
            <div className='actions'>
                <div className="filter-options-wrapper" style={{display: 'flex', flexDirection:'flex-end', width: 'auto'}}>
                    <div className="download" onClick={handleShowDownloadModal}>
                        <img src={`${process.env.PUBLIC_URL}/assets/icons/download.svg`} alt="Download as" />
                    </div>
                    <DownloadModal showModal={showDownloadModal} setShowModal={setShowDownloadModal} />
                </div>
                <div className="search-wrapper">
                    <div className="rectangle-group">
                        <img
                            className="search-icon"
                            src={process.env.PUBLIC_URL + "/assets/icons/search.svg"}
                            alt="Search"
                        />
                        <DebounceInput
                            minLength={2}
                            debounceTimeout={500}
                            className="text-area"
                            placeholder={"Search"}
                            onChange={handleSearch}
                        />
                    </div>
                </div></div>
        </div>
    )
}

export default Header