import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useSearch from '../../../hooks/useSearch'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import { BASE_URL, api } from '../../../utils/api'
import moment from 'moment'
// import useYouthSectionStore from '../../../store/youthSectionStore'
// import Swal from 'sweetalert2'

const AllEvents = ({ query }) => {
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const { data, lastElement, loading, error } = useInfiniteScroll(
        BASE_URL + api.events.getAll,
        {
            page: page,
            size: size,
        },
        setPage,
        "results", true
    );

    const searchData = useSearch(
        BASE_URL + api.events.getAll,
        {
            page: 1,
            size: 100,
            name: query
        },
        setPage,
        "results"
    );

    return (
        <>
            <table>
                <thead>
                    <tr className='head'>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Date Of Birth</th>
                        <th>Gender</th>
                        <th>Mobile</th>
                        <th>Zone</th>
                        <th>Parish</th>
                        <th>Cultural Events</th>
                        <th>Sports Events</th>
                    </tr>
                </thead>
                <tbody>
                    {(query ? searchData?.data : data)?.map((user, index) => {
                        return <tr ref={data.length === index + 1 ? lastElement : null} key={index}>
                            <td style={{width: '150px'}}>{user.name}</td>
                            <td style={{width: '250px'}}>{user.email}</td>
                            <td style={{width: '120px'}}>{moment(user.dob).format("DD/MM/YYYY")}</td>
                            <td style={{width: '100px'}}>{user.gender}</td>
                            <td style={{width: '120px'}}>{user.mobile}</td>
                            <td style={{width: '150px'}}>{user.deanery || "-"}</td>
                            <td style={{width: '200px'}}>{user.parish}</td>
                            <td><ol>{[...user?.culturalEvents]?.map((evt) => {
                                return <li>{evt}</li>
                            })}</ol></td>
                            <td><ol>{[...user.sportsEvents]?.map((evt) => {
                                return <li>{evt}</li>
                            })}</ol></td>
                        </tr>
                    })}
                </tbody>
            </table>
            {loading && <div className="loading"><span>Loading</span></div>}
            {error && <div className="error"><span>Unable to load data</span></div>}
        </>

    )
}

export default AllEvents