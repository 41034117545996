import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Header from './Header'
import AllEvents from './EComponents/AllEvents';

const Events = () => {
  const location = useLocation();

  const [query, setQuery] = useState('');

  return (
    <div className='wrapper'>
      <div className="main-div">
        <Header setQuery={setQuery} />
        <div className="data-list">
            <AllEvents />
        </div>
      </div>
    </div>
  )
}

export default Events