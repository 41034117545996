import { useEffect } from "react";
import Auth from "../utils/classes/classAUTH";

const useAuthTokenExpiration = (callback) => {
  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = Auth.getToken(); // Get token from storage
      if (token) {
        const expirationTime = Auth.getExpirationTime(); // Decode JWT token
        const currentTime = Math.floor(Date.now());
        // Check if token is expired
        if (expirationTime < currentTime) {
          alert("Session expired, please log in again.");
          callback();
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);
};

export default useAuthTokenExpiration;
