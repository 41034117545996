import React, { useEffect, useState } from 'react'
import "./YouthSection.scss"
import { useLocation, useNavigate } from 'react-router-dom';
import Fallback from './Fallback';
import Header from './Header';
import ActiveYouth from './YSComponents/ActiveYouth';
import NewRequest from './YSComponents/NewRequest';
import UpdateRequest from './YSComponents/UpdateRequest';
import InctiveYouth from './YSComponents/InactiveYouth';

const YouthSection = () => {
  const navigate = useNavigate()
  const location = useLocation();

  const [query, setQuery] = useState('')

  useEffect(() => {
    if (location.pathname.includes("update-request")) return;
    navigate("active-youth")
    // eslint-disable-next-line
  }, [])

  return (
    <div className='wrapper'>
      <div className="main-div">
        <Header setQuery={setQuery} />
        <div className="data-list">
          {location.pathname.includes("/active-youth") && <ActiveYouth query={query} />}
          {location.pathname.includes("/new-request") && <NewRequest query={query} />}
          {location.pathname.includes("/update-request") && <UpdateRequest query={query} />}
          {location.pathname.includes("/inactive-youth") && <InctiveYouth query={query} />}
          {location.pathname === "/youth-section" && <Fallback />}
        </div>
      </div>
    </div>
  )
}

export default YouthSection