// export const BASE_URL = "http://localhost:8000/api/v1";
export const BASE_URL = "https://api.myfaithbook.in/api/v1";
// export const BASE_URL = "http://ec2-13-201-36-52.ap-south-1.compute.amazonaws.com/api/v1";

export const api = {
  auth: {
    login: "/user/login",
    logout: "/user/logout",
    requestPassword: "/user/requestPasswordReset"
  },
  user: {
    getAll: "/user/getusers",
    updateUser: "/user/update"
  },
  dashboard: {
    stats: "/user/home",
    birthdays: "/user/birthdayWeb"
  },
  roleManagement: {
    getAll: "/user/fetch",
    post: "/user/register",
    put: "/user/update",
    delete: "/user/delete"
  },
  listing: {
    getAll: "/user/listing",
    post: "/user/listing",
    put: "/user/listing",
    delete: "/user/listing",
    downloadFile: "https://faithbookbucker.s3.ap-south-1.amazonaws.com/Documents/Listings/",
    feed: "/user/feed",
    fileName: "Listing+Format.xlsx",
    filePath: ".xlsx",
    listByRole: "/user/listingByRole"
  },
  notification: {
    getAll: "/notification",
    post: "/notification",
    put: "/notification",
    delete: "/notification"
  },
  news: {
    getAll: "/news",
    post: "/news",
    put: "/news",
    delete: "/news"
  },
  campus: {
    getAll: "/campus",
    post: "/campus",
    put: "/campus",
    delete: "/campus"
  },
  gallery: {
    getAll: "/gallery",
    post: "/gallery",
    put: "/gallery",
    delete: "/gallery"
  },
  contact: {
    getAll: "/contact",
    create: "/contact",
    update: "/contact",
    delete: "/contact"
  },
  hamburger: {
    getAll: "/hamburger",
    post: "/hamburger",
    put: "/hamburger",
    delete: "/hamburger"
  },
  events: {
    getAll: "/event",
    // post: "/event",
    // put: "/event",
    // delete: "/event",
    // download: "/"
  },
};
