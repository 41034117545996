import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
// import Bottom from '../components/Footer/Bottom/Bottom'
import Navbar from "../components/Navbar/Navbar";
import RoleManagementPage from "../pages/RoleManagementPage";
import AnnouncementPage from "../pages/AnnouncementPage";
import YouthSectionPage from "../pages/YouthSectionPage";
import Bottom from "../components/Footer/Bottom";
import NotificationPage from "../pages/NotificationPage";
import ListingPage from "../pages/ListingPage";
import ContactPage from "../pages/ContactPage";
import GalleryPage from "../pages/GalleryPage";
import CampusConnect from "../pages/CampusConnect";
import MemberDetailsPage from "../pages/MemberDetailsPage";
import Hamburger from "../pages/Hamburger";
import EventPage from "../pages/EventPage";

const AdminRouter = () => {
  return (
    <div className="layout">
      <Navbar />
      <main>
        <Routes>
          <Route path="/dashboard" element={<HomePage />} />
          {/* Role Management */}
          <Route path="/role-management" element={<RoleManagementPage />}>
            <Route path="all" />
            <Route path="admin" />
            <Route path="region" />
            <Route path="diocese" />
          </Route>
          {/* Youth Section */}
          <Route path="/youth-section" element={<YouthSectionPage />}>
            <Route path="active-youth" />
            <Route path="new-request" />
            <Route path="update-request" />
            <Route path="inactive-youth" />
          </Route>

          {/* Announcement */}
          <Route path="/announcement" element={<AnnouncementPage />}>
            <Route path="all" />
            <Route path="news" />
            <Route path="announcement" />
          </Route>
          {/* NOTIFICATION */}
          <Route path="/notification" element={<NotificationPage />} />

          {/* ContactUs */}
          <Route path="/contact-us" element={<ContactPage />} />

          {/* GALLERY */}
          <Route path="/gallery" element={<GalleryPage />} />

          <Route path="/campus-connect" element={<CampusConnect />} />

          <Route path="/hamburger" element={<Hamburger />} />

          {/* LISTING */}
          <Route path="/listing" element={<ListingPage />} />

          {/* EVENTS */}
          <Route path="/events" element={<EventPage />} />

          {/* MEMBER DETAILS */}
          <Route path="youth-section/preview" element={<MemberDetailsPage />}>
            <Route path="personal-details" />
            <Route path="spiritual-details" />
            <Route path="family-details" />
            <Route path="education" />
            <Route path="work-experience" />
            <Route path="licenses" />
            <Route path="contact" />
          </Route>
          {/* Fallback to /dashboard */}
          <Route path="*" element={<Navigate to={"/dashboard"} />} />
        </Routes>
      </main>
      <Bottom />
    </div>
  );
};

export default AdminRouter;
