import Cookies from "js-cookie";
import { constants } from "../constants";

class Auth {
  static authenticateUser(token) {
    const expirationTime = new Date();
    Cookies.set(constants.auth.token, token);
    Cookies.set('exp', expirationTime.getTime() + 7 * 24 * 60 * 60 * 1000)
  }
  static getToken() {
    return Cookies.get(constants.auth.token); // "t0k3n"
  }
  static removeToken() {
    Cookies.remove(constants.auth.token); // "t0k3n"
    Cookies.remove('exp')
  }
  static getExpirationTime() {
    return Math.floor((+Cookies.get('exp')))
  }
}

export default Auth;
